import {PageLayout} from "../styles/layouts/page-layout"
import {TextContainer} from "../styles/containers/container"
import { useEffect} from "react";
// import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

//Page that In3D redirects to
// sends a post req with ID from redirect over to server
const Status = (props) => {
    // const [firstName, setFirstName] = useState();
    const param = useParams();
    const scanId = param.id
    const body = {
        modelId: scanId
    }

    //token req
    const getToken = async () => {
        const result = await axios({
            method: 'POST',
            url: 'https://cardsserver.drawandcode.com/auth/check-validation',
            //    url: 'http://localhost:3600/api/query-user-data', 
            // data: body,
            headers: {
                // 'Access-Control-Allow-Origin': '*',
                // 'Content-Type': 'application/json',
            }
            })
            .then((response) => {
                    const token = response.data;
                // window.location.href(`https://localhost:3000/redirect/${response}`)
                return token
            })
        .catch((error) => {
            console.log(`Error: ${error}`)
            return error.data
        })
        return result
    }

    //post req
    const postFormData = async () => {
        const tokenReq = await getToken();
        const token = tokenReq.data
        console.log(token)   
          
        const result = await axios({
                    method: 'POST',
                    url: 'https://cardsserver.drawandcode.com/api/query-user-data',
                    //    url: 'http://localhost:3600/api/query-user-data', 
                    data: body,
                    headers: {
                        // 'Access-Control-Allow-Origin': '*',
                        // 'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                    })
                    .then((response) => {
                            const data = response.data;
                        // window.location.href(`https://localhost:3000/redirect/${response}`)
                        return data
                    })
                .catch((error) => {
                    console.log(`Error: ${error}`)
                    return error.data
                })
                return result
        }
        useEffect(() => {
                postFormData()
                // .then(() => 
                //     setFirstName(requestData))
        }, [])
    return (
        <PageLayout  display={'flex'} flexDirection={'column'}>
            <TextContainer className="success-text">
                <p>Your 3D model is being processed and will be ready in approximately 5 minutes.
                In the meantime you can collect your QR code sticker.</p>
                <p>Thanks, Draw and Code</p>   
            </TextContainer>
        </PageLayout>


    )
};
export default Status;