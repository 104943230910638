import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import SignUp from "./components/SignUp";
import Account from "./components/Account";
import Status from "./components/Status";
import Redirect from "./components/Redirect";
import PrivacyPolicy from "./components/PrivacyPolicy";
import "./App.css";

//main component, 
//before React root creation
//controls routing of app
//look up react router dom to learn how to use routing in React
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <header className="App-header">
        {window.location.href=== 'https://arcards.drawandcode.com/privacy-policy'  ? 
         <h1>Draw & Code Privacy Policy </h1> : <h1>AR Business Cards</h1>}
        </header>
        <Routes>
          <Route exact path= "/" element={<Home/>} />
          <Route exact path= "/sign-up" element={<SignUp/>} />
          <Route exact path= "/redirect/:id" element={<Redirect/>} />
          {/* <Route exact path= "/redirect" element={<Redirect/>} /> */}
          <Route exact path="/status/:id" element={<Status/>} />
          <Route exact path= "/account" element={<Account/>} />
          <Route exact path= "/privacy-policy" element={<PrivacyPolicy/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
