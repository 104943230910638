import Form from "./Forms/Form";
import {PageLayout} from '../styles/layouts/page-layout';

//Page where user enters details
//Nested form component
//handles data sending 

const SignUp = (props) => {
    
    return (
        <PageLayout display={'flex'} flexDirection={'column'}>
            <div>
                <Form />
            </div>
        </PageLayout>
    )
};
export default SignUp;
