import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PageLayout } from "../styles/layouts/page-layout";
import { TextContainer } from "../styles/containers/container";

//Determines whether user is redirected to create their 3D scan
//takes scan ID from signup and sends to In3D
//has other responses if scan ID failed or already exists
const Redirect = (props) => {
    const param = useParams();
    const [message, setMessage] = useState("");
    const scanId = param.id
    
    //use this to encode
    //albionresearch.com/tools/urlencode
    
    const url = `https://www.arcards.drawandcode.com/status/${scanId}`;
    const urlEncoded = encodeURIComponent(url);
    var hasNumber = /\d/; 

    useEffect(() => {
        if (scanId === "exists") {
            setMessage("Your model already exists. If you wish to create more models, please contact Draw and Code.")
        }
        else if (scanId === null ||scanId === "undefined") {
            setMessage("There's been an error creating your scan ID. Please try again or contact a member of Draw and Code team.")
        }
        else if (!hasNumber.test(scanId)) {
            setMessage("There's been an error creating your scan ID. Please try again or contact a member of Draw and Code team.")
        } else {
            setMessage("redirecting")
            const redirect = () => {
                //make sure redirect url is encoded
                window.location.href = `https://scan.in3d.io/?scan_id=${scanId}&redirect_url=${urlEncoded}`
            }
            setTimeout(redirect, 250)
        }
    }, [scanId]);


    return (
        <PageLayout   display={'flex'} flexDirection={'column'}>
            <TextContainer>
                <p>{message}</p>
            </TextContainer>
        </PageLayout>
    )
};
export default Redirect;