import {PageLayout} from '../styles/layouts/page-layout';

//App privacy policy page
const PrivacyPolicy = (props) => {
    return (
        <PageLayout  display={'flex'} flexDirection={'column'}>
            <div class="padding">
                <div class="privacy-text-container">
                    <div class="privacy-text">
                        <br></br>
                        <h2>Our contact details </h2> 
                        Name: Draw & Code	
                        Address: 24 Hardman Street, Liverpool, L1 9AX
                        Phone Number: 0151 329 9000 
                        E-mail: hello@drawandcode.com
                        <br></br>
                        <h2>The type of personal information we collect</h2> 
                        We currently collect and process the following information: 
                        Name and surname
                        Phone number
                        Business email address
                        Instagram profile
                        LinkedIn profile
                        Twitter profile
                        <br></br>
                        <h2>How we get the personal information and why we have it </h2>
                        Most of the personal information we process is provided to us directly by you for one of the following reasons: 
                        Display in the Calling Cards AR Experience
                        Update of Draw & Code clients and partners’ database
                        
                        Under the EU General Data Protection Regulation (EU GDPR), the lawful bases we rely on for processing this information are:
                        <h2>Your consent</h2> You are able to remove your consent at any time. You can do this by contacting “hello@drawandcode.com”.
                        
                        <h2>How we store your personal information </h2>
                        Your information is securely stored.
                        We keep “Name, Surname and Business Email Address” for two years. We will then 
                        dispose of your information by deleting your data from our database. 
                        
                        Date: 18/10/2022
                        
                        <h2>Your data protection rights </h2>
                        Under data protection law, you have rights including: 
                        <br></br>
                        <br></br>
                        <strong>Your right of access</strong> - You have the right to ask us for copies of your personal information. 
                        <br></br>
                        <strong>Your right to rectification</strong> - You have the right to ask us to rectify personal information you think is inaccurate. You also have the right to ask us to complete information you think is incomplete. 
                        <br></br>
                        <strong>Your right to erasure</strong> - You have the right to ask us to erase your personal information in certain circumstances. 
                        <br></br>
                        <strong>Your right to restriction of processing</strong> - You have the right to ask us to restrict the processing of your personal information in certain circumstances. 
                        <br></br>
                        <strong>Your right to object to processing</strong> - You have the the right to object to the processing of your personal information in certain circumstances. 
                        <br></br>
                        <strong>Your right to data portability </strong>- You have the right to ask that we transfer the personal information you gave us to another organisation, or to you, in certain circumstances. 
                        You are not required to pay any charge for exercising your rights. If you make a request, we have one month to respond to you. 
                        Please contact us at hello@drawandcode.com if you wish to make a request. 
                        
                        <h2>How to complain</h2>
                        If you have any concerns about our use of your personal information, you can make a complaint to us at: 24 Hardman Street, Liverpool, L1 9AX or sending an email to “hello@drawandcode.com”
                    </div>
                </div>
            </div>
        </PageLayout>
    )
};
export default PrivacyPolicy;