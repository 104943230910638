import styled from "styled-components";

export const InputStyle = styled.input `
    width: 95%;
    min-width: 260px;
    height: 25px;
    text-align: center;
    outline: none;
    border: 0;
    box-shadow: none;
    border-radius: 20px;
`
export const InputSpacing = styled.div `
    display:flex;
    flex-direction: column;
    padding: 10px 0px 25px 0px;
`

export const SubmitStyle = styled.input `
    height: 50px;
    width: 260px;
    min-width: 200px;
    margin: 20px 0px 20px 0px;
    text-align: center;
    background-color: #FFED00;
    font-size: 22px;
    font-family: 'Saira', sans-serif;
    text-align: center;
    outline: none;
    border-radius: 10px;
    border: 0;
    box-shadow: none;
    border-radius: 10px;
    transition: 1s;
    &:hover {
            cursor: pointer;
            background-color: #FF0DF2;
            color:#494443;
            transition: 1s;
    }
`
export const SubmitStyleGreyed = styled.input `
    height: 50px;
    width: 260px;
    min-width: 200px;
    margin: 20px 0px 20px 0px;
    text-align: center;
    background-color: #494443;
    color: #FFED00;
    font-size: 22px;
    font-family: 'Saira', sans-serif;
    text-align: center;
    outline: none;
    border-radius: 10px;
    border: 0;
    box-shadow: none;
    border-radius: 10px;
    transition: 1s;
    &:hover {
            cursor: pointer;
            background-color: #FF0DF2;
            color:#494443;
            transition: 1s;
    }
`