import { Link } from "react-router-dom";
import {PageLayout} from '../styles/layouts/page-layout';


//home page
const Home = (props) => {
    return (
        <PageLayout display={'flex'} flexDirection={'column'}>
            <p>Brought to you by Draw and Code</p>
             <img src="./Logo_Y(alpha).png" alt="By Draw and Code" width={250} height={250}/>
            <div className="get-scanned-container">
                <div>
               
                </div>
                <button className="dnc-btn">
                    <Link to={"/sign-up"}>
                        Get Scanned
                    </Link>
                </button>
            </div>
        </PageLayout>
    )
}
export default Home;