
//empty template for accounts in future
const Account = (props) => {
    return (
        <>
            <div>
                
            </div>
        </>
    )
};
export default Account;