import styled from "styled-components";

export const FormStyle = styled.form `
    display: ${( props ) => props.display};
    flex-direction: ${( props ) => props.flexDirection};
    /* column, could use flex numbers instead? */
    width: 340px;
    min-width: 300px;
    padding: 10px;
    text-align: left;
`

export const InputStyle = styled.input `

`