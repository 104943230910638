import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import reCAPTCHA from "react-google-recaptcha"
import { FormStyle } from "../../styles/forms/form";
import { InputSpacing, InputStyle, SubmitStyle, SubmitStyleGreyed } from "../../styles/forms/inputs";


//form template, currently only used in Signup however.
//needs a refactor.... increased flexibility
const Form = (props) => {
    //react use states
    //set the state according to UI interactions
    const [isFirst, setIsFirst] = useState(true);
    const [checked, setChecked] = useState(false);
    // const [message, setMessage] = useState("");
    const [blankmessage, setBlankMessage] = useState("");
    const [blankmessagenext, setBlankMessageNext] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [company, setCompany] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [insta, setInsta] = useState("");
    const [twit, setTwit] = useState("");
    // const [fb, setFb] = useState("");
    const [linkd, setLinkd] = useState("https://www.linkedin.com/in/");


    let navigate = useNavigate ();

    //sending body
    const body = {
        firstName: firstName,
        lastName: lastName,
        company: company,
        email: email,
        phone: phone,
        instagram: insta,
        twitter: twit,
        linkedIn: linkd,
    }

    const validateEmail = (input) => {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(input.toLowerCase());
    }

    // const validatePhone = (input) => {
    //     var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    //     return re.test(input);
    // }

    const next = (e) => {
        var emailValid = validateEmail(email);
        // var phoneValid = validatePhone(phone);
        if (emailValid !== true) {
            setBlankMessageNext("The email address you've entered is invalid.")
        } 
        // else if (phoneValid !== true) {
        //     setBlankMessageNext("The phone number you've entered is invalid.")
        // }
        else {
            if (firstName === "" | lastName === "" | email === "" | phone === "") {
                // e.preventDefault()  
                setBlankMessageNext("Please fill in all fields")
            } else {
                e.preventDefault() 
                setIsFirst(false)
                setBlankMessageNext("")
                setBlankMessage("")
            }
        }
    }

    const back = (e) => {
            e.preventDefault() 
            setIsFirst(true)
            setBlankMessageNext("")
    }

    //token req
    const getToken = async () => {
        const result = await axios({
            method: 'POST',
            url: 'https://cardsserver.drawandcode.com/auth/check-validation',
            //    url: 'http://localhost:3600/auth/check-validation', 
            headers: {
                // 'Access-Control-Allow-Origin': '*',
                // 'Content-Type': 'application/json',
                }
            })
            .then((response) => {
                const token = response.data;
                console.log(response.data)
                // window.location.href(`https://localhost:3000/redirect/${response}`)
                return token
            })
            .catch((error) => {
            console.log(`Error: ${error}`)
            return error
        })
        return result
    }

    //signup req
    const postFormData = async (e) => {
        if (firstName === "" | lastName === "" | email === "" | phone === "") {
            e.preventDefault()  
            setBlankMessage("Please fill in all fields")
        } else {
            if (checked) {
                e.preventDefault() 
                const tokenReq = await getToken();
                const token = tokenReq.data
                console.log(token)
                
                const result = await axios({
                           method: 'post',
                        //    url: 'http://localhost:3600/api/sign-up',
                           url: 'https://cardsserver.drawandcode.com/api/sign-up',
                           data: body,
                           headers: {
                            // 'Host' : 'www.staging-cardsserver.drawandcode.com',›
                            'Authorization': `Bearer ${token}`
                            }
                        })
                        .then((response) => {
                                console.log(response);
                                const id = response.data;
                                return id
                          })
                        .catch((error) => {
                                console.log(`Error: ${error}`)
                                return error.data
                            })
                //navigates user to redirect according to result
                setTimeout(function redirect() {
                    navigate(`/redirect/${result}`, {replace: true}) 
                }, 250)
            } else {
                e.preventDefault()  
                // setMessage("You must tick agree to continue")
            }
        }
        
    }


        return (
            <>
                <FormStyle display={"flex"} flexDirection={"column"} onSubmit={(e) =>postFormData(e)}>
                    {/* changes form content according to whether is first or not */}
                    {isFirst ? (  
                        <>
                        <h2>Contact Details</h2>
                    <label>First Name</label>
                    <InputSpacing>
                        <InputStyle name="firstName" type={"text"} value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                    </InputSpacing>
                    <label>Last Name</label>
                    <InputSpacing>
                        <InputStyle name="lastName" type={"text"} value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                    </InputSpacing>
                    <label>Company or Title</label>
                    <InputSpacing>
                        <InputStyle name="company" type={"text"} value={company} onChange={(e) => setCompany(e.target.value)}/>
                    </InputSpacing>
                    <label >Email</label>
                    <InputSpacing>
                        <InputStyle name="email" type={"email"} value={email} onChange={(e) => setEmail(e.target.value)}/>  
                    </InputSpacing>
                    <label>Phone</label>
                    <InputSpacing>
                        <InputStyle name="phone" type={"tel"} value={phone} onChange={(e) => setPhone(e.target.value)}/>
                    </InputSpacing>
                    <InputSpacing>
                    <div className="submit-center">
                        <button className="dnc-next-btn" onClick={(e) => next(e)}>
                                Next                 
                        </button>
                        <p className="message">{blankmessagenext}</p>
                    </div>
                    
                    </InputSpacing>
                        </>
                    ) : (
                        <>
                    <h2>Social Links</h2>
                    <label>Instagram Handle</label>
                    <InputSpacing>
                        <InputStyle name="insta" type={"text"} value={insta} onChange={(e) => setInsta(e.target.value)}/>
                    </InputSpacing>
                    {/* <label>Facebook</label>
                    <InputSpacing>
                        <InputStyle name="fb" type={"text"} value={fb} onChange={(e) => setFb(e.target.value)}/>
                    </InputSpacing> */}
                    <label>Twitter Handle</label>
                    <InputSpacing>
                        <InputStyle name="twit" type={"text"} value={twit} onChange={(e) => setTwit(e.target.value)}/>
                    </InputSpacing>
                    <label>LinkedIn Profile Link</label>
                    <InputSpacing>
                        <InputStyle name="linkd" type={"text"} value={linkd} onChange={(e) => setLinkd(e.target.value)}/>
                    </InputSpacing>
                    <div className="small-print">
                        {/* <label>I have read and agree to <Link to="/privacy-policy"><span class="link"> the privacy policy</span></Link>.</label> */}
                        <label className="small-print-label">I have read and agree to <a className="link" href="https://arcards.drawandcode.com/privacy-policy" target="_blank" rel="noopener noreferrer"> the privacy policy</a></label>
                        <input type="checkbox"
                                defaultChecked={checked}
                                onChange={() => setChecked(!checked)}
                        /> 
                        {/* <reCAPTCHA /> */}
                    </div>

                    <div className="submit-center">
                    <InputSpacing>
                    <button className="dnc-next-btn" onClick={(e) => back(e)}>
                                Back           
                    </button>
                    {checked ? 
                        <SubmitStyle type="submit" value="Enter"/> : <SubmitStyleGreyed type="submit" value="Agree"/>}

                        {/* <p className="message">{message}</p> */}
                        <p className="message">{blankmessage}</p>
                    </InputSpacing>
                    </div>
                    </>
                    
                    )}
            </FormStyle>
        </>
    )};


export default Form;